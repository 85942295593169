<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <div class="icon-settings-bold">
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3567 15.5C12.3786 15.5 11.4406 15.1313 10.7491 14.4749C10.0575 13.8185 9.66894 12.9283 9.66894 12C9.66894 11.0717 10.0575 10.1815 10.7491 9.52513C11.4406 8.86875 12.3786 8.5 13.3567 8.5C14.3347 8.5 15.2727 8.86875 15.9643 9.52513C16.6559 10.1815 17.0444 11.0717 17.0444 12C17.0444 12.9283 16.6559 13.8185 15.9643 14.4749C15.2727 15.1313 14.3347 15.5 13.3567 15.5ZM21.1852 12.97C21.2274 12.65 21.259 12.33 21.259 12C21.259 11.67 21.2274 11.34 21.1852 11L23.4084 9.37C23.6086 9.22 23.6613 8.95 23.5348 8.73L21.4276 5.27C21.3011 5.05 21.0166 4.96 20.7848 5.05L18.1613 6.05C17.6134 5.66 17.0444 5.32 16.3806 5.07L15.9908 2.42C15.9694 2.30222 15.9047 2.19543 15.8083 2.11855C15.7119 2.04168 15.5899 1.99968 15.464 2H11.2494C10.986 2 10.7647 2.18 10.7226 2.42L10.3327 5.07C9.66894 5.32 9.09997 5.66 8.55208 6.05L5.92852 5.05C5.69672 4.96 5.41224 5.05 5.2858 5.27L3.17852 8.73C3.04155 8.95 3.10476 9.22 3.30496 9.37L5.52814 11C5.48599 11.34 5.45438 11.67 5.45438 12C5.45438 12.33 5.48599 12.65 5.52814 12.97L3.30496 14.63C3.10476 14.78 3.04155 15.05 3.17852 15.27L5.2858 18.73C5.41224 18.95 5.69672 19.03 5.92852 18.95L8.55208 17.94C9.09997 18.34 9.66894 18.68 10.3327 18.93L10.7226 21.58C10.7647 21.82 10.986 22 11.2494 22H15.464C15.7274 22 15.9486 21.82 15.9908 21.58L16.3806 18.93C17.0444 18.67 17.6134 18.34 18.1613 17.94L20.7848 18.95C21.0166 19.03 21.3011 18.95 21.4276 18.73L23.5348 15.27C23.6613 15.05 23.6086 14.78 23.4084 14.63L21.1852 12.97Z" fill="#CBD6E3" />
        </svg>
        <span class="icon-title">{{title}}</span>
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    };
</script>

<style scoped>
    .icon-settings-bold {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;
    }

    .icon-title {
        font-size: 15px;
    }
</style>